import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: colors.blueGrey.darken3,
        secondary: colors.blueGrey.lighten4,
        accent: colors.deepPurple.lighten2,
        error: colors.red.darken2,
        info: colors.blue.accent2,
        success: colors.lightGreen.accent4,
        warning: colors.amber,
      },
    },
    options: {
      // see https://vuetifyjs.com/en/features/theme/#custom-properties
      customProperties: true,
    },
  },
});
