import Vue from 'vue';
import Vuelidate from 'vuelidate';
import titleMixin from './mixins/titleMixin';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import './assets/fonts/goodTimes-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.mixin(titleMixin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
