<template>
  <v-app>
    <v-main app>
      <v-container
        fill-height
        fluid
      >
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card elevation="12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>

              <v-card-title>Veuillez vous connecter</v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    @keypress.enter="submit(email, password)"
                  />

                  <v-text-field
                    id="password"
                    label="Mot de passe"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    @keypress.enter="submit(email, password)"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" :to="{ path: '/' }">Back</v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  @click="submit(email, password)"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="snack" multi-line>
          {{ snackText }}
          <template #action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snack = false"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/*
 * This is a special layout, because it is basically similar to a view/ file
 */
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  title: 'Page de connexion',

  data: () => ({
    email: '',
    password: '',
    snack: false,
    snackText: '',
    adminLogin: false,
  }),
  methods: {
    submit(email, password) {
      this.login({ email, password })
        .then(() => {
          // console.log(this.$route.query);
          if (this.$route.query.redirect) {
            this.$router.push(String(this.$route.query.redirect));
          } else if (this.adminLogin) {
            this.$router.push({ name: 'dashboard_organisations' });
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch((err) => {
          this.snackText = `An unknown error occured! ${err}`;
          if (err.response.status === 401) {
            this.snackText = 'La paire email/mot de passe fournie est invalide !';
          }
          this.snack = true;
        });
    },
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.adminLogin = Boolean(newRoute.meta?.adminPage);
      },
    },
  },
};
</script>

<style scoped>

</style>
