import axios from 'axios';
import authHeader from './auth-header';

export function login(user) {
  return axios
    .post(`${process.env.VUE_APP_API_URL}user/login`, {
      email: user.email,
      password: user.password,
    })
    .then((response) => {
      if (response.data.token) {
        // FIXME: store in httponly cookie instead of localstorage
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
}

export function logout() {
  // FIXME
  localStorage.removeItem('user');
}

export function register(user) {
  return axios.post(`${process.env.VUE_APP_API_URL}users`, {
    name: user.username,
    email: user.email,
    password: user.password,
    admin: user.admin,
    customer: user.admin ? null : user.customer,
  }, { headers: authHeader() });
}
