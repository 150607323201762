<template>
  <v-app>
    <v-app-bar app dark color="#313131" :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-container class="py-0 fill-height">
        <v-avatar class="mr-3" color="grey darken-2" />
        <v-responsive max-width="256">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
            disabled
          />
        </v-responsive>

        <v-spacer />

        <v-btn text @click="logout">Déconnexion</v-btn>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <v-list dense nav>
        <v-list-item
          link
          :to="item.to"
          v-for="item in navItems"
          :key="item.title"
          exact
          :disabled="item.disabled"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main app>
      <router-view />
    </v-main>

    <!--<v-footer app></v-footer>-->
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { getSelf } from '@/services/user.service';

export default {
  name: 'Back',
  title: 'MargeCom - Dashboard',

  async mounted() {
    if (this.loggedIn) {
      try {
        const user = await getSelf();
        this.user = user.data;
        if (!this.user.admin) {
          this.logout();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      await this.$router.push({ name: 'login_back' });
    }
  },
  data: () => ({
    navItems: [
      {
        title: 'Organisations',
        icon: 'mdi-account-multiple',
        to: { name: 'dashboard_organisations' },
        disabled: false,
      },
      {
        title: 'Contacts',
        icon: 'mdi-account-box',
        to: { name: 'dashboard_contacts' },
        disabled: false,
      },
      {
        title: 'Catalogues',
        icon: 'mdi-animation',
        to: { name: 'dashboard_catalogs' },
        disabled: false,
      },
      {
        title: 'Maintenances',
        icon: 'mdi-cogs',
        to: { name: 'dashboard_maintenances' },
        disabled: true,
      },
      {
        title: 'Equipements',
        icon: 'mdi-package',
        to: { name: 'dashboard_equipments' },
        disabled: true,
      },
      {
        title: 'Abonnements',
        icon: 'mdi-wallet-membership',
        to: { name: 'dashboard_subscriptions' },
        disabled: true,
      },
      {
        title: 'Propositions',
        icon: 'mdi-clipboard-text',
        to: { name: 'dashboard_propositions' },
        disabled: true,
      },
      /*
      {
        title: 'Clients',
        icon: 'mdi-account-multiple',
        to: { name: 'dashboard_home' },
      },
      {
        title: 'Utilisateurs',
        icon: 'mdi-contacts',
        to: { name: 'dashboard_users' },
      },
      {
        title: 'Paramètres',
        icon: 'mdi-cogs',
        to: { name: 'dashboard_settings' },
      },
      */
    ],
    drawer: null,
    user: null,
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    logout() {
      this.storeLogout()
        .then(() => this.$router.push({ name: 'login_back' }))
        .catch(console.error);
    },
    ...mapActions({
      storeLogout: 'auth/logout',
    }),
  },
};
</script>
