import axios from 'axios';
import authHeader from './auth-header';

export function getSelf() {
  return axios.get(`${process.env.VUE_APP_API_URL}user/me`, { headers: authHeader() });
}

export function getUser(email) {
  return axios.get(`${process.env.VUE_APP_API_URL}user/${encodeURIComponent(email)}`, { headers: authHeader() });
}

export function getCustomerSelf() {
  return axios.get(`${process.env.VUE_APP_API_URL}customer/me`, { headers: authHeader() });
}

export function ingenicoPayment(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}customer/payment`, data, { headers: authHeader() });
}

export function updateUser(email, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}user/${encodeURIComponent(email)}`, data, { headers: authHeader() });
}

export function deleteUser(email) {
  return axios.delete(`${process.env.VUE_APP_API_URL}user/${encodeURIComponent(email)}`, { headers: authHeader() });
}
