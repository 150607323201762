<template>
  <v-app>
    <v-app-bar app color="white" flat hide-on-scroll height="100px">
      <v-container class="py-0 fill-height">
        <v-col cols="4"
               class="d-flex flex-row align-start mb-auto">
          <v-menu offset-y=""
                  v-if="collapseNav">
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon v-bind="attrs"
                                  v-on="on">
              </v-app-bar-nav-icon>
            </template>
            <v-list flat>
              <v-list-item v-for="item in navItems"
                           :key="item.text">
                <v-btn link=""
                       :to="item.to"
                       exact=""
                       text
                       :ripple="false"
                       active-class="nav-current-page"
                       class="font-weight-bold">
                  {{ item.text }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list class="d-flex flex-row justify-start"
                  v-else
                  flat>
            <v-list-item v-for="item in navItems"
                         :key="item.text"
                         class="pa-0">
              <v-btn link=""
                     :to="item.to"
                     exact=""
                     text
                     :ripple="false"
                     active-class="nav-current-page"
                     class="font-weight-bold">
                {{ item.text }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="4"
               class="d-flex flex-row justify-center align-end mt-auto">
          <v-btn
            link=""
            :to="{name: 'home'}"
            :ripple="false"
            text
            class="btn--image"
          >
            <v-img lazy-src="@/assets/LogoVec.svg"
                   max-width="150"
                   src="@/assets/LogoVec.svg"
                   contain />
          </v-btn>
        </v-col>

        <v-col cols="4"
               class="d-flex flex-row justify-end align-start mb-auto">
          <v-menu offset-y=""
                  v-if="collapseAccount">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs"
                                  v-on="on">
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-list flat>
              <v-list-item>
                <v-btn text
                       @click="logout">
                  Déconnexion
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text
                 @click="logout" v-else>
            <v-icon left>mdi-account-circle-outline</v-icon>
            Déconnexion
          </v-btn>
        </v-col>
      </v-container>
    </v-app-bar>

    <div class="background--grey"></div>

    <v-main app class="mt-10">
      <router-view />
    </v-main>

    <!--<v-footer app></v-footer>-->
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { getSelf } from '@/services/user.service';

export default {
  name: 'Front',
  title: 'MargeCom',

  data: () => ({
    navItems: [
      {
        text: 'Accueil',
        to: {
          name: 'home',
        },
      },
      {
        text: 'Mes documents',
        to: {
          name: 'documents',
        },
      },
      {
        text: 'Maintenance',
        to: {
          name: 'maintenance',
        },
      },
      {
        text: 'Hébergement',
        to: {
          name: 'hosting',
        },
      },
      {
        text: 'Paiement',
        to: {
          name: 'payment',
        },
      },
    ],
    user: null,
  }),
  async mounted() {
    if (this.loggedIn) {
      try {
        const user = await getSelf();
        this.user = user.data;
        if (this.user.admin) {
          this.logout();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      await this.$router.push({ name: 'login_front' });
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    collapseNav() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return true;
        case 'lg':
          return true;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
    collapseAccount() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return false;
        case 'md':
          return false;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    logout() {
      this.storeLogout()
        .then(() => this.$router.push({ name: 'login_front' }))
        .catch(console.error);
    },
    ...mapActions({
      storeLogout: 'auth/logout',
    }),
  },
};
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

  a.nav-current-page::before{
    background: none;
  }
  a.nav-current-page{
    color: #63c9e3 !important;
  }
  .background--grey {
    position: absolute;
    top: calc(100px + 20rem);
    left: 0;
    width: 100%;
    height: calc(100% - 100px - 15rem);
    background: #eee;
  }
  .font-family-GoodTimes{
      font-family: "Fredoka One", cursive;
  }

  .btn--image::before{
    background-color:transparent !important;
  }
</style>
